import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Radio,
  DialogContent,
} from "@material-ui/core";

interface CustomPickerProps {
  title?: string;
  visible: boolean;
  data: Array<{ [key: string]: number | string }>;
  displayKey: string;
  idKey: string;
  preSelectedIDs: number[];
  multiple?: boolean;
  onCancel: () => void;
  onSubmit: (selectedValues: object[]) => void;
}

const CustomPicker: React.FC<CustomPickerProps> = ({
  title,
  visible,
  data,
  displayKey,
  idKey,
  preSelectedIDs,
  multiple,
  onCancel,
  onSubmit,
}) => {
  const [selectedItemIDs, setSelectedItemIDs] = useState([...preSelectedIDs]);

  const _isIdSelected = (id: string) => {
    const foundIndex = selectedItemIDs.find((itemId) => `${itemId}` === id);
    return foundIndex !== undefined;
  };

  const onSubmitClick = () => {
    const selected = data.filter((item) => _isIdSelected(`${item[idKey]}`));
    onSubmit(selected);
    setSelectedItemIDs([...preSelectedIDs]);
  };

  const itemClicked = (
    item: { [key: string]: React.ReactText },
    index: number
  ) => {
    const id = `${item[idKey]}`;
    if (multiple) {
      if (_isIdSelected(id)) {
        const newArray = selectedItemIDs.filter((_id) => `${_id}` !== id);
        setSelectedItemIDs([...newArray]);
      } else {
        setSelectedItemIDs([...selectedItemIDs, Number(id)]);
      }
    } else {
      const selected = data.filter((i) => i[idKey] === item[idKey]);
      console.log(selected);
      onSubmit(selected);
    }
  };

  return (
    <Dialog
      onClose={onCancel}
      open={visible}
      fullWidth
      maxWidth={"xs"}
      scroll={"paper"}
    >
      {title && <DialogTitle id="simple-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <List>
          {data.map((item, index) => {
            const label = item[displayKey] as string;
            const id = `${item[idKey]}`;
            return (
              <ListItem
                button
                key={`${index}`}
                onClick={() => itemClicked(item, index)}
              >
                <FormControlLabel
                  control={
                    multiple ? (
                      <Checkbox checked={_isIdSelected(id)} />
                    ) : (
                      <Radio checked={_isIdSelected(id)} />
                    )
                  }
                  label={label}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      {multiple && (
        <DialogActions>
          <Button onClick={onSubmitClick} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomPicker;
