import axios, { AxiosRequestConfig } from "axios";

import Utils from "./Utils";
import { APIPath, APIs } from "./Const";
import { ResponseData } from "./Types";
import { dispatch } from "./GlobalState";
import * as Actions from "./GlobalActions";

type ServiceType = "GET" | "POST";

export default class ServiceManager {
  static CallService = async (
    service: string,
    params: object = {},
    type: ServiceType = "POST",
    showsLoader: boolean = true
  ): Promise<ResponseData | undefined> => {
    if (showsLoader) {
      dispatch(Actions.ShowLoader());
    }

    const url = Utils.getHost() + APIPath + service;
    console.log(url);
    console.log(params);

    try {
      const config: AxiosRequestConfig = {
        method: "POST",
        timeout: 20 * 1000,
        params: params,
      };

      const { data } = await axios(url, config);
      console.log(data);

      const response: ResponseData = data;

      return response;
    } catch (error) {
      const message = error.message;
      if (message.includes("timeout")) {
        dispatch(
          Actions.ShowSnackBar({ message: "Request timed-out", type: "error" })
        );
      } else if (message.includes("Network Error")) {
        dispatch(
          Actions.ShowSnackBar({ message: "Network error", type: "error" })
        );
      }
    } finally {
      if (showsLoader) {
        dispatch(Actions.HideLoader());
      }
    }
    return undefined;
  };
}
