import React, { useState } from "react";
import { MasjidModel } from "../../common/Types";
import {
  Dialog,
  DialogContent,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@material-ui/core";
import { ArrowBack as BackArrowIcon } from "@material-ui/icons";

import { dispatch } from "../../common/GlobalState";
import * as Actions from "../../common/GlobalActions";
import MasjidMapScreen from "./MasjidMapScreen";

interface Props {
  masjid: MasjidModel;
  visible: boolean;
  onCancel: () => void;
}

const MasjidDetailsScreen: React.FC<Props> = ({
  masjid,
  visible,
  onCancel,
}) => {
  const {
    MasjidName,
    Address,
    Enquiry,
    TimeModifiedOn,
    ContactNo,
    Website,
    JamaatFajr,
    JamaatZohar,
    JamaatAsr,
    JamaatMagrib,
    JamaatIsha,
    KhutbaJumma,
    IsJummaMasjid,
    JamaatEid,
    Longitude,
    Latitude,
  } = masjid;

  const [mapVisible, setMapVisible] = useState(false);

  return (
    <>
      <Dialog
        onClose={onCancel}
        open={visible}
        fullWidth
        maxWidth={"xs"}
        scroll={"paper"}
        style={{ backgroundColor: "transparent" }}
      >
        <DialogContent className={"animatedGradient"}>
          <Card>
            <CardContent>
              <BackArrowIcon
                style={{ position: "absolute", top: 30, left: 30 }}
                onClick={onCancel}
              />
              <NamaazItem title={"Namaaz"} time={"Jamaat"} isHeading />
              <Divider />
              <NamaazItem title={"Fajr"} time={JamaatFajr} />
              <NamaazItem title={"Zuhr"} time={JamaatZohar} />
              <NamaazItem title={"Asr"} time={JamaatAsr} />
              <NamaazItem title={"Magrib"} time={JamaatMagrib} />
              <NamaazItem title={"Isha"} time={JamaatIsha} />
              {IsJummaMasjid && KhutbaJumma && (
                <NamaazItem title={"Jumuah"} time={KhutbaJumma!} />
              )}
              {JamaatEid && JamaatEid! !== "-" && (
                <NamaazItem title={"Eid"} time={JamaatEid} />
              )}
            </CardContent>
          </Card>
          <div style={{ marginTop: 8, marginBottom: 8 }}>
            <DetailItem
              Icon={require("../../assets/MasjidDetails/ic_masjid_name.png")}
              details={MasjidName}
            />
            <DetailItem
              Icon={require("../../assets/MasjidDetails/ic_masjid_address.png")}
              details={Address}
              onClick={() => {
                if (Latitude && Longitude) {
                  setMapVisible(true);
                } else {
                  dispatch(
                    Actions.ShowSnackBar({
                      message: "Location not available for this masjid",
                      type: "warning",
                    })
                  );
                }
              }}
            />
            {TimeModifiedOn && (
              <DetailItem
                Icon={require("../../assets/MasjidDetails/ic_masjid_last_updated.png")}
                details={TimeModifiedOn!}
              />
            )}
            {ContactNo && (
              <DetailItem
                Icon={require("../../assets/MasjidDetails/ic_masjid_contact_no.png")}
                details={ContactNo!}
                onClick={() => {
                  console.log(ContactNo);
                  window.open(`tel://${ContactNo}`);
                }}
              />
            )}
            {Website && (
              <DetailItem
                Icon={require("../../assets/MasjidDetails/ic_masjid_website.png")}
                details={Website!}
                onClick={() => {
                  console.log(Website);
                  if (Website.includes("@")) {
                    window.open(`mailto:${Website}`, "_blank");
                  } else {
                    window.open(`${Website}`, "_blank");
                  }
                }}
              />
            )}
            {Enquiry && (
              <DetailItem
                Icon={require("../../assets/MasjidDetails/ic_masjid_enquiry.png")}
                details={Enquiry!}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
      <MasjidMapScreen
        masjidName={MasjidName}
        location={{
          latitude: Number(masjid.Latitude) || 0.0,
          longitude: Number(masjid.Longitude) || 0.0,
        }}
        onCancel={() => setMapVisible(false)}
        visible={mapVisible}
      />
    </>
  );
};

const NamaazItem: React.FC<{
  title: string;
  time: string;
  isHeading?: boolean;
}> = ({ title, time, isHeading }) => (
  <div style={{ display: "flex" }}>
    <div style={{ flex: 1 }}>
      <Typography variant={isHeading ? "h6" : "subtitle1"} align={"center"}>
        {title}
      </Typography>
    </div>
    <div style={{ flex: 1 }}>
      <Typography variant={isHeading ? "h6" : "subtitle1"} align={"center"}>
        {time}
      </Typography>
    </div>
  </div>
);

const DetailItem: React.FC<{
  Icon: any;
  details: string;
  onClick?: () => void;
}> = ({ Icon, details, onClick }) => (
  <div style={{ display: "flex", marginTop: 8 }}>
    <img src={Icon} style={{ width: 20, height: 20, marginRight: 10 }} />
    <Typography
      variant={"subtitle1"}
      onClick={onClick}
      style={{
        color: "white",
        textDecoration: onClick ? "underline" : undefined,
      }}
    >
      {details}
    </Typography>
  </div>
);

export default MasjidDetailsScreen;
