import React, { useState, useEffect } from "react";

import { Animated, AnimatedProps } from "react-animated-css";

interface AnimatedVisibilityProps
  extends React.PropsWithChildren<{}>,
    AnimatedProps {}

const AnimatedVisibility: React.FC<AnimatedVisibilityProps> = ({
  isVisible,
  children,
  animationIn,
  animationOut,
  animationInDuration = 300,
  animationOutDuration = 300
}) => {
  const [noDisplay, setNoDisplay] = useState(!isVisible);
  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => setNoDisplay(true), 650);
    } else {
      setNoDisplay(false);
    }
  }, [isVisible]);

  const style = noDisplay ? { display: "none" } : undefined;
  return (
    <Animated
      animationIn={animationIn}
      animationOut={animationOut}
      isVisible={isVisible}
      animationInDuration={animationInDuration}
      animationOutDuration={animationOutDuration}
      style={style}
    >
      {children}
    </Animated>
  );
};

export default AnimatedVisibility;
