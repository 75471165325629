import { Dispatch } from "react";
import { createStore } from "react-hooks-global-state";
import { applyMiddleware } from "redux";

import { Environments } from "./Const";
import { GlobalState, Action } from "./Types";

const defaultState: GlobalState = {
  Environment: Environments.Live,
  IsLoading: false,
  CountryCityPickerVisible: false,
  AreaPickerVisible: false,
  SelectedCountry: undefined,
  SelectedCity: undefined,
  SelectedArea: undefined,
  SnackBar: undefined,
  SelectedLocation: undefined,
  LocationPickerVisible: false,
};

const LOCAL_STORAGE_KEY = "aqslh_local_storage_key";
const parseState = (str: string | null): GlobalState | null => {
  try {
    const state = JSON.parse(str || "");
    const parsedState: GlobalState = {
      ...defaultState,
      ...state,
      IsLoading: false,
    };
    return parsedState;
  } catch (e) {
    return null;
  }
};
const stateFromStorage = parseState(localStorage.getItem(LOCAL_STORAGE_KEY));
const initialState: GlobalState = stateFromStorage || defaultState;

const reducer = (state: GlobalState, action: Action): GlobalState => {
  switch (action.type) {
    case "SaveEnvironment":
      return { ...state, Environment: action.payload };
    case "ShowLoader":
      return { ...state, IsLoading: true };
    case "HideLoader":
      return { ...state, IsLoading: false };
    case "ShowCountryCityPicker":
      return { ...state, CountryCityPickerVisible: true };
    case "HideCountryCityPicker":
      return { ...state, CountryCityPickerVisible: false };
    case "ShowAreaPicker":
      return { ...state, AreaPickerVisible: true };
    case "HideAreaPicker":
      return { ...state, AreaPickerVisible: false };
    case "SaveSelectedCountry":
      return { ...state, SelectedCountry: action.payload };
    case "SaveSelectedCity":
      return { ...state, SelectedCity: action.payload };
    case "SaveSelectedArea":
      return { ...state, SelectedArea: action.payload };
    case "ShowSnackbar":
      return { ...state, SnackBar: action.payload };
    case "HideSnackbar":
      return { ...state, SnackBar: undefined };
    case "ShowLocationPicker":
      return { ...state, LocationPickerVisible: true };
    case "HideLocationPicker":
      return { ...state, LocationPickerVisible: false };
    case "SaveSelectedLocation":
      return { ...state, SelectedLocation: action.payload };
    default:
      return state;
  }
};

const saveStateToStorage = ({ getState }: { getState: () => GlobalState }) => (
  next: Dispatch<Action>
) => (action: Action) => {
  const returnValue = next(action);
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(getState()));
  return returnValue;
};

const { dispatch, useGlobalState, getState } = createStore(
  reducer,
  initialState,
  applyMiddleware(saveStateToStorage)
);

export { dispatch, useGlobalState, getState };
