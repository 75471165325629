import React from "react";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";

import { NavBarAction } from "../../common/Types";

interface NavBarProps {
  title: string;
  leftButton?: NavBarAction;
  rightButtons?: NavBarAction[];
}

const NavBar = ({ title, leftButton, rightButtons }: NavBarProps) => {
  return (
    <AppBar position="sticky" elevation={2}>
      <Toolbar variant={"regular"} style={{ paddingRight: 0, paddingLeft: 0 }}>
        {leftButton && (
          <div>
            <IconButton
              onClick={leftButton.onPress}
              color="inherit"
              style={{ padding: 12 }}
            >
              {leftButton.Icon}
            </IconButton>
          </div>
        )}
        <Typography
          variant="h6"
          noWrap
          style={{ marginLeft: 8, marginRight: 8 }}
        >
          {title}
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <div>
          {rightButtons?.map(({ Icon, onPress }, index) => (
            <IconButton onClick={onPress} color="inherit" key={`${index}`}>
              {Icon}
            </IconButton>
          ))}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
