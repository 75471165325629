const Environments = {
  Live: "Live",
  Demo: "Demo",
  LocalIIS: "Local IIS",
  VSIIS: "VS IIS Express",
};

const Hosts = {
  Live: "http://www.awqatesalah.com/",
  Demo: "http://demo.awqatesalah.com/",
  LocalIIS: "http://192.168.10.168/",
  VSIIS: "http://192.168.10.168:45455/",
};

const APIPath = "API_AwqatESalah__V2_2/";

const APIs = {
  GetAllCountries: "GetAllCountries",
  GetAllAreasByCityID: "GetAllAreasByCityID",
  GetMasjidsByAreaID: "GetMasjidsByAreaID",
  GetNearByMasjids: "GetNearByMasjids",
  GetMasjidByMasjidID: "GetMasjidByMasjidID",
  GetHijriDate: "GetHijriDate",
};

const firebaseConfig = {
  apiKey: "AIzaSyCnPb2k78mZKmgsIK6SrVUDRKmXMM22EJw",
  authDomain: "awqat-e-salah-f3e48.firebaseapp.com",
  databaseURL: "https://awqat-e-salah-f3e48.firebaseio.com",
  projectId: "awqat-e-salah-f3e48",
  storageBucket: "awqat-e-salah-f3e48.appspot.com",
  messagingSenderId: "703045233283",
  appId: "1:703045233283:web:f3fda6934255fe42c189b6"
};

export { Environments, Hosts, APIPath, APIs, firebaseConfig };
