import React from "react";
import { Snackbar, Slide } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { TransitionProps } from "@material-ui/core/transitions/transition";

import { useGlobalState, dispatch } from "../../common/GlobalState";
import * as Actions from "../../common/GlobalActions";

const TransitionLeft = (props: TransitionProps) => {
  return <Slide {...props} direction="left" />;
};

const SnackbarAlert: React.FC = () => {
  const [snackBar] = useGlobalState("SnackBar");
  const { message, type, onClose } = snackBar || {};

  const onDismiss = () => {
    dispatch(Actions.HideSnackBar());
    if (onClose) {
      onClose!();
    }
  };

  return (
    <Snackbar
      open={snackBar !== undefined}
      autoHideDuration={5000}
      onClose={onDismiss}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      TransitionComponent={TransitionLeft}
    >
      <MuiAlert onClose={onClose} severity={type} variant={"filled"}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarAlert;
