import React, { useState, useEffect } from "react";
import { RouteChildrenProps } from "react-router-dom";
import {
  List,
  ListItem,
  Card,
  Typography,
  CardContent,
  CardActions,
  Button,
  CardMedia,
  Divider,
  Fab,
} from "@material-ui/core";
import {
  ArrowBack as BackArrowIcon,
  Search as SearchIcon,
  LocationOn as LocationOnIcon,
  StarBorder as StarBorderIcon,
  Star as StarIcon,
} from "@material-ui/icons";

import ScreenWrapper from "../../components/ScreenWrapper/ScreenWrapper";
import NavBar from "../../components/NavBar/NavBar";
import ServiceManager from "../../common/ServiceManager";
import { MasjidModel, MasjidFilter } from "../../common/Types";
import { APIs } from "../../common/Const";
import SearchBar from "../../components/SearchBar/SearchBar";
import MasjidListFilter from "./MasjidListFilter";
import Utils from "../../common/Utils";
import MasjidDetailsScreen from "../MasjidDetailsScreen/MasjidDetailsScreen";

interface Props
  extends RouteChildrenProps<{
    areaId: string;
    longitude: string;
    latitude: string;
  }> {}

const MasjidListScreen: React.FC<Props> = ({ match, history }) => {
  const [allMasjids, setAllMasjids] = useState<MasjidModel[]>([]);
  const [visibleMasjids, setVisibleMasjids] = useState<MasjidModel[]>([]);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState<MasjidFilter | undefined>(undefined);
  const [searchBarVisible, setSearchBarVisible] = useState<boolean>(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [selectedMasjid, setSelectedMasjid] = useState<MasjidModel | undefined>(
    undefined
  );

  const loadMasjids = async () => {
    const { params } = match || {};
    const { areaId = "0", longitude = undefined, latitude = undefined } =
      params || {};

    if (Number(areaId) > 0) {
      //SEARCH BY AREA
      const response = await ServiceManager.CallService(
        APIs.GetMasjidsByAreaID,
        {
          AreaID: areaId,
        }
      );
      if (response) {
        const { ResultData } = response;
        setAllMasjids(ResultData as MasjidModel[]);
      }
    } else {
      //SEARCH BY LOCATION
      const response = await ServiceManager.CallService(APIs.GetNearByMasjids, {
        Longitude: longitude,
        Latitude: latitude,
      });
      if (response) {
        const { ResultData } = response;
        setAllMasjids(ResultData as MasjidModel[]);
        setVisibleMasjids(ResultData as MasjidModel[]);
      }
    }
  };

  useEffect(() => {
    loadMasjids();
  }, []);

  useEffect(() => {
    prepareMasjids();
  }, [searchText, allMasjids, filter]);

  const prepareMasjids = () => {
    let masjids: MasjidModel[] = [];
    if (searchText.length > 0) {
      const searched = allMasjids.filter((masjid) => {
        const { MasjidName, MasjidName2, Address, AreaName } = masjid;
        const text = searchText.toLowerCase();
        if (
          MasjidName.toLowerCase().includes(text) ||
          MasjidName2.toLowerCase().includes(text) ||
          Address.toLowerCase().includes(text) ||
          AreaName.toLowerCase().includes(text)
        ) {
          return true;
        }
        return false;
      });
      masjids = [...searched];
    } else {
      masjids = [...allMasjids];
    }
    if (filter) {
      if (filter.Area && filter.Area.length > 0) {
        const area = filter.Area.toLowerCase();
        masjids = masjids.filter((masjid) => {
          if (
            masjid.AreaName.toLowerCase().includes(area) ||
            masjid.Address.toLowerCase().includes(area)
          ) {
            return true;
          }
          return false;
        });
      }
      if (filter.Time) {
        const { Time } = filter;
        const time = Utils.convertDateToString(Time, "hh:mm"); //Time.getHours() + ":" + Time.getMinutes();
        console.log("Time :", time);
        masjids = masjids.filter((masjid) => {
          const {
            JamaatFajr,
            JamaatZohar,
            JamaatAsr,
            JamaatMagrib,
            JamaatIsha,
            KhutbaJumma,
            JamaatEid,
          } = masjid;
          if (
            JamaatFajr.includes(time) ||
            JamaatZohar.includes(time) ||
            JamaatAsr.includes(time) ||
            JamaatMagrib.includes(time) ||
            JamaatIsha.includes(time) ||
            (KhutbaJumma && KhutbaJumma!.includes(time)) ||
            (JamaatEid && JamaatEid!.includes(time))
          ) {
            return true;
          }
          return false;
        });
      }
    }
    setVisibleMasjids(masjids);
  };

  return (
    <ScreenWrapper>
      <NavBar
        title={"Masjid"}
        leftButton={{
          Icon: <BackArrowIcon />,
          onPress: () => history.goBack(),
        }}
        rightButtons={[
          {
            Icon: <SearchIcon />,
            onPress: () => setSearchBarVisible(!searchBarVisible),
          },
        ]}
      />
      <SearchBar
        visible={searchBarVisible}
        placeholder={"Search"}
        onTextChange={setSearchText}
        style={{ marginTop: 10, marginLeft: 8, marginRight: 8 }}
      />
      <div
        style={{
          height: "92vh",
          overflow: "scroll",
        }}
      >
        <List style={{ marginLeft: 8, marginRight: 8 }}>
          {visibleMasjids.map((masjid, index) => (
            <MasjidItem
              masjid={masjid}
              key={index}
              onClick={(masjid: MasjidModel) => setSelectedMasjid(masjid)}
            />
          ))}
        </List>
      </div>
      <Fab
        color={"primary"}
        style={{ position: "absolute", bottom: 8, left: 8 }}
        onClick={() => setFilterVisible(true)}
      >
        {"FILTER"}
      </Fab>
      {filter && (
        <Fab
          color={"primary"}
          style={{ position: "absolute", bottom: 8, right: 8 }}
          onClick={() => setFilter(undefined)}
        >
          {"CLEAR"}
        </Fab>
      )}
      <MasjidListFilter
        visible={filterVisible}
        onClose={() => setFilterVisible(false)}
        onSubmit={(filter) => {
          setFilter(filter);
          setFilterVisible(false);
        }}
      />
      {selectedMasjid && (
        <MasjidDetailsScreen
          masjid={selectedMasjid!}
          visible={selectedMasjid !== undefined}
          onCancel={() => setSelectedMasjid(undefined)}
        />
      )}
    </ScreenWrapper>
  );
};

const MasjidItem: React.FC<{
  masjid: MasjidModel;
  onClick: (masjid: MasjidModel) => void;
}> = React.memo(({ masjid, onClick }) => {
  const { MasjidName, Address, IsPreAddedMasjid, TimeModifiedOn } = masjid;
  const redColor = "#FE080A";
  const greenColor = "#00B54F";
  return (
    <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Card style={{ display: "flex", flex: 1, borderRadius: 8 }}>
        <CardMedia>
          <div
            style={{
              backgroundColor: IsPreAddedMasjid ? redColor : greenColor,
              width: 5,
              height: "100%",
            }}
          />
        </CardMedia>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <CardContent onClick={() => onClick(masjid)}>
            <Typography gutterBottom variant="h6">
              {MasjidName}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {Address}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ marginTop: 4, marginBottom: 4 }}
            >
              Updated on {TimeModifiedOn}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {IsPreAddedMasjid
                ? "This masjid has no admin"
                : "This masjid has admin"}
            </Typography>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              fullWidth
              style={{ textTransform: "none", color: "black" }}
              startIcon={<LocationOnIcon />}
            >
              See Location
            </Button>
            <Divider orientation="vertical" flexItem />
            <Button
              fullWidth
              style={{ textTransform: "none", color: "black" }}
              startIcon={<StarBorderIcon />}
            >
              Add to Favorites
            </Button>
          </CardActions>
        </div>
      </Card>
    </ListItem>
  );
});

export default MasjidListScreen;
