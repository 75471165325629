import React, { useState, useEffect } from "react";

import ServiceManager from "../../common/ServiceManager";
import { APIs } from "../../common/Const";
import { AreaModel } from "../../common/Types";
import { useGlobalState, getState, dispatch } from "../../common/GlobalState";
import CustomPicker from "../../components/CustomPicker/CustomPicker";
import * as Actions from "../../common/GlobalActions";

const AreaPicker = () => {
  const [areaPickerVisible] = useGlobalState("AreaPickerVisible");
  const [selectedArea] = useGlobalState("SelectedArea");

  const [areas, setAreas] = useState<AreaModel[]>([]);

  const loadAreas = async () => {
    const { SelectedCity } = getState();
    const response = await ServiceManager.CallService(
      APIs.GetAllAreasByCityID,
      { CityID: SelectedCity?.CityID }
    );
    if (response) {
      const { ResultData } = response;
      setAreas(ResultData as Array<AreaModel>);
    }
  };

  useEffect(() => {
    if (areaPickerVisible) {
      loadAreas();
    }
  }, [areaPickerVisible]);

  const closeAreaPicker = () => {
    dispatch(Actions.HideAreaPicker());
  };

  if (!areaPickerVisible) {
    return null;
  }

  return (
    <CustomPicker
      title={"Select City"}
      visible={areaPickerVisible && areas.length > 0}
      data={areas}
      displayKey={"AreaName"}
      idKey={"AreaID"}
      preSelectedIDs={selectedArea ? [selectedArea!.AreaID] : []}
      onCancel={closeAreaPicker}
      onSubmit={(selectedAreas) => {
        if (selectedAreas.length > 0) {
          const area = selectedAreas[0] as AreaModel;
          dispatch(Actions.SaveSelectedArea(area));
          closeAreaPicker();
        }
      }}
    />
  );
};

export default AreaPicker;
