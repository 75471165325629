import DateFnsAdapter from "@date-io/date-fns";

import { getState } from "./GlobalState";
import { Environments, Hosts } from "./Const";

export default class Utils {
  static getHost = (): string => {
    const globalState = getState();
    const { Environment } = globalState;
    switch (Environment) {
      case Environments.Live:
        return Hosts.Live;
      case Environments.Demo:
        return Hosts.Demo;
      case Environments.LocalIIS:
        return Hosts.LocalIIS;
      case Environments.VSIIS:
        return Hosts.VSIIS;
      default:
        return Hosts.Demo;
    }
  };

  static sleep = (seconds: number = 1): Promise<void> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, seconds * 1000);
    });
  };

  static getMonthNameForNumber = (
    monthNumber: number,
    isEnglish: boolean
  ): string => {
    let englishMonthName = "";
    let HijriMonthName = "";
    switch (monthNumber) {
      case 1:
        englishMonthName = "January";
        HijriMonthName = "Muharram";
        break;
      case 2:
        englishMonthName = "February";
        HijriMonthName = "Safar";
        break;
      case 3:
        englishMonthName = "March";
        HijriMonthName = "Rabiul Awwal";
        break;
      case 4:
        englishMonthName = "April";
        HijriMonthName = "Rabiul Aakhir";
        break;
      case 5:
        englishMonthName = "May";
        HijriMonthName = "Jumadal Ula";
        break;
      case 6:
        englishMonthName = "June";
        HijriMonthName = "Jumadal Aakhirah";
        break;
      case 7:
        englishMonthName = "July";
        HijriMonthName = "Rajab";
        break;
      case 8:
        englishMonthName = "August";
        HijriMonthName = "Sha'ban";
        break;
      case 9:
        englishMonthName = "September";
        HijriMonthName = "Ramadan";
        break;
      case 10:
        englishMonthName = "October";
        HijriMonthName = "Shawwal";
        break;
      case 11:
        englishMonthName = "November";
        HijriMonthName = "Zul Qa'dah";
        break;
      case 12:
        englishMonthName = "December";
        HijriMonthName = "Zul Hijjah";
        break;
      default:
        break;
    }
    if (isEnglish) {
      return englishMonthName;
    } else {
      return HijriMonthName;
    }
  };

  static convertDateToString(date: Date, format: string): string {
    const dateFns = new DateFnsAdapter();
    return dateFns.format(date, format);
  }
}
