import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import GoogleMapReact from "google-map-react";

import { Location } from "../../common/Types";

interface Props {
  masjidName: string;
  visible: boolean;
  location: Location;
  onCancel: () => void;
}

const MasjidMapScreen: React.FC<Props> = ({
  masjidName,
  visible,
  location,
  onCancel,
}) => {
  return (
    <Dialog
      open={visible}
      onClose={onCancel}
      fullWidth
      maxWidth={"xs"}
      scroll={"paper"}
    >
      <DialogContent>
        <div style={{ height: "70vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_MAP_KEY!,
            }}
            defaultZoom={18}
            defaultCenter={{ lat: location.latitude, lng: location.longitude }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              const marker = new maps.Marker({
                position: {
                  lat: location.latitude,
                  lng: location.longitude,
                },
                map,
              });
              const infoWindow = new maps.InfoWindow({
                content: `<div>
                <div style="font-size: 16px; font-weight: bold">
                  ${masjidName}
                </div>
              </div>`,
              });
              marker.addListener("click", () => {
                infoWindow.open(map, marker);
              });
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MasjidMapScreen;
