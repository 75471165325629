import React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import MasjidListScreen from "./screens/MasjidListScreen/MasjidListScreen";
import CountryCityPicker from "./screens/CountryCityPicker/CountryCityPicker";
import AreaPicker from "./screens/AreaPicker/AreaPicker";
import SnackbarAlert from "./components/SnackbarAlert/SnackbarAlert";
import LocationPicker from "./components/LocationPicker/LocationPicker";
import LoaderHud from "./components/LoaderHud/LoaderHud";

const App: React.FC = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () => createMuiTheme({ palette: { primary: blue, type: "light" } }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path={"/"} exact component={HomeScreen} />
          <Route
            path={"/Masjids/:areaId/:longitude/:latitude"}
            component={MasjidListScreen}
          />
        </Switch>
      </BrowserRouter>
      <CountryCityPicker />
      <AreaPicker />
      <SnackbarAlert />
      <LocationPicker />
      <LoaderHud />
    </ThemeProvider>
  );
};

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
