import React from "react";
import { Box } from "@material-ui/core";

const ScreenWrapper: React.FC = ({ children }) => {
  return (
    <Box minHeight="98vh" style={{ backgroundColor: "#F6F6F6" }}>
      {children}
    </Box>
  );
};

export default ScreenWrapper;
