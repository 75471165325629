import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import MapPicker from "react-google-map-picker";

import { Location } from "../../common/Types";
import { dispatch, useGlobalState } from "../../common/GlobalState";
import * as Actions from "../../common/GlobalActions";

const LocationPicker: React.FC = () => {
  const [selectedLocation] = useGlobalState("SelectedLocation");
  const [locationPickerVisible] = useGlobalState("LocationPickerVisible");

  const [pickedLocation, setPickedLocation] = useState<Location>(
    selectedLocation || {
      latitude: 0.0,
      longitude: 0.0,
    }
  );

  const locationChanged = (latitude: number, longitude: number) => {
    console.log(latitude + ", " + longitude);
    setPickedLocation({ latitude, longitude });
  };

  const onSubmitClick = () => {
    dispatch(Actions.SaveSelectedLocation(pickedLocation));
    dispatch(Actions.HideLocationPicker());
  };

  const onCancel = () => {
    dispatch(Actions.HideLocationPicker());
  };

  return (
    <Dialog
      open={locationPickerVisible}
      onClose={onCancel}
      fullWidth
      maxWidth={"xs"}
      scroll={"paper"}
    >
      <DialogContent>
        <MapPicker
          defaultLocation={{
            lat: pickedLocation.latitude,
            lng: pickedLocation.longitude,
          }}
          zoom={11}
          style={{ height: "400px" }}
          onChangeLocation={locationChanged}
          // onChangeZoom={handleChangeZoom}
          apiKey={process.env.REACT_APP_MAP_KEY!}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmitClick} color="primary" autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationPicker;
