import React from "react";

import { Card } from "@material-ui/core";

interface LogoProps {
  style?: React.CSSProperties;
  width?: number;
  height?: number;
  rounded?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  style,
  width = 100,
  height = 100,
  rounded,
}) => {
  return (
    <Card
      style={{
        width,
        height,
        borderRadius: rounded ? width / 2 : undefined,
        ...style,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        style={{
          width: "92%",
          height: "92%",
          borderRadius: rounded ? width / 2 : undefined,
        }}
        src={require("../../assets/logo.png")}
      />
    </Card>
  );
};

export default Logo;
