import React from "react";
import { Dialog, DialogTitle, CircularProgress } from "@material-ui/core";

import { useGlobalState, dispatch } from "../../common/GlobalState";
import * as Actions from "../../common/GlobalActions";

const LoaderHud: React.FC = () => {
  const [isLoading] = useGlobalState("IsLoading");
  return (
    <Dialog open={isLoading} /*onClose={() => dispatch(Actions.HideLoader())}*/>
      <CircularProgress style={{ alignSelf: "center", marginTop: 15 }} />
      <DialogTitle>Loading..</DialogTitle>
    </Dialog>
  );
};

export default LoaderHud;
