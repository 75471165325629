import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormGroup,
} from "@material-ui/core";

import ServiceManager from "../../common/ServiceManager";
import { APIs } from "../../common/Const";
import { useGlobalState, dispatch } from "../../common/GlobalState";
import * as Actions from "../../common/GlobalActions";
import { CountryModel, CityModel } from "../../common/Types";
import Utils from "../../common/Utils";

const CountryCityPicker = () => {
  const [countryCityPickerVisible] = useGlobalState("CountryCityPickerVisible");

  const [selectedCountry] = useGlobalState("SelectedCountry");
  const [selectedCity] = useGlobalState("SelectedCity");

  const [countries, setCountries] = useState<CountryModel[]>(
    selectedCountry ? [selectedCountry] : []
  );

  const onCountrySelected = (country: CountryModel) => {
    dispatch(Actions.SaveSelectedCountry(country));
    if (country.Cities.length > 0) {
      const city = country.Cities[0];
      // dispatch(Actions.SaveSelectedCity(city));
      onCitySelected(city);
    }
  };

  const onCitySelected = (city: CityModel) => {
    dispatch(Actions.SaveSelectedCity(city));
    dispatch(Actions.SaveSelectedArea(undefined));
  };

  const loadCountries = async () => {
    const response = await ServiceManager.CallService(APIs.GetAllCountries);
    if (response) {
      const ResultData = response.ResultData as CountryModel[];
      if (ResultData.length > 0) {
        setCountries(ResultData);
        if (!selectedCountry) {
          const country = ResultData[0];
          onCountrySelected(country);
        }
      }
    }
  };

  useEffect(() => {
    if (countryCityPickerVisible) {
      loadCountries();
    }
  }, [countryCityPickerVisible]);

  const onClose = () => {
    if (!selectedCity) {
      //SHOW MESSAGE
      dispatch(
        Actions.ShowSnackBar({
          message: "Select Country and State first",
          type: "error",
        })
      );
    } else {
      dispatch(Actions.HideCountryCityPicker());
    }
  };

  return (
    <Dialog
      open={countryCityPickerVisible && countries.length > 0}
      onClose={onClose}
      fullWidth
      maxWidth={"xs"}
      scroll={"paper"}
    >
      <DialogTitle>Country</DialogTitle>
      <DialogContent>
        <FormGroup>
          <InputLabel id="countryPickerLabel">Country</InputLabel>
          <Select
            labelId="countryPickerLabel"
            value={selectedCountry ? selectedCountry!.CountryID : ""}
          >
            {countries.map((country) => (
              <MenuItem
                onClick={() => onCountrySelected(country)}
                value={country.CountryID}
                key={country.CountryID}
              >
                {country.CountryName}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>

        <FormGroup style={{ marginTop: 16 }}>
          <InputLabel id="cityPickerLabel">State/County</InputLabel>
          <Select
            labelId="cityPickerLabel"
            value={selectedCity ? selectedCity!.CityID : ""}
          >
            {selectedCountry &&
              selectedCountry!.Cities.map((city) => (
                <MenuItem
                  onClick={() => onCitySelected(city)}
                  value={city.CityID}
                  key={city.CityID}
                >
                  {city.CityName}
                </MenuItem>
              ))}
          </Select>
        </FormGroup>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: 10 }}
          onClick={onClose}
        >
          Submit
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default CountryCityPicker;
