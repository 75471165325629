import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
} from "@material-ui/core";
import { Schedule as ScheduleIcon } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { MasjidFilter } from "../../common/Types";

interface MasjidListFilterProps {
  visible: boolean;
  onSubmit: (filter: MasjidFilter) => void;
  onClose: () => void;
}

const MasjidListFilter: React.FC<MasjidListFilterProps> = ({
  visible,
  onSubmit,
  onClose,
}) => {
  const [area, setArea] = useState<string | null>(null);
  const [time, setTime] = useState<MaterialUiPickersDate | null>(null);

  const onSubmitClick = () => {
    onSubmit({ Area: area, Time: time });
  };

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      fullWidth
      maxWidth={"xs"}
      scroll={"paper"}
    >
      <DialogTitle>Filter</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TextField
            autoFocus
            margin="dense"
            id="area"
            label="Enter Area"
            type="text"
            fullWidth
            value={area}
            onChange={(e) => setArea(e.target.value)}
          />
          <KeyboardTimePicker
            margin="dense"
            id="time"
            label="Namaaz Time"
            value={time}
            onChange={(date) => setTime(date)}
            fullWidth
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            keyboardIcon={<ScheduleIcon />}
          />

          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              fullWidth
              style={{ marginTop: 10, marginRight: 4 }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: 10, marginLeft: 4 }}
              onClick={onSubmitClick}
            >
              Submit
            </Button>
          </div>
        </MuiPickersUtilsProvider>
      </DialogContent>
    </Dialog>
  );
};

export default MasjidListFilter;
