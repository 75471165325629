import React from "react";
import { Paper, IconButton, InputBase } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import AnimatedVisibility from "../AnimatedVisibility/AnimatedVisibility";

interface SearchBarProps {
  placeholder: string;
  onTextChange?: (text: string) => void;
  visible: boolean;
  style?: React.CSSProperties;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  onTextChange,
  visible,
  style,
}) => {
  return (
    <AnimatedVisibility
      isVisible={visible}
      animationIn="fadeIn"
      animationOut="fadeOut"
    >
      <Paper
        component="form"
        style={{
          display: "flex",
          alignItems: "center",
          flex: 1,
          margin: 4,
          ...style,
        }}
      >
        <IconButton type="submit" style={{ padding: 10 }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          autoFocus={visible}
          style={{ marginLeft: 1, flex: 1 }}
          placeholder={placeholder}
          inputProps={{ "aria-label": placeholder }}
          onChange={(e) => onTextChange && onTextChange!(e.target.value)}
        />
      </Paper>
    </AnimatedVisibility>
  );
};

export default SearchBar;
