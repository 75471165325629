import {
  Action,
  CountryModel,
  CityModel,
  AreaModel,
  SnackbarProps,
  Location,
} from "./Types";

export const SaveSelectedCountry = (country: CountryModel): Action => {
  const action: Action = { type: "SaveSelectedCountry", payload: country };
  return action;
};

export const SaveSelectedCity = (city: CityModel): Action => {
  const action: Action = { type: "SaveSelectedCity", payload: city };
  return action;
};

export const SaveSelectedArea = (area: AreaModel | undefined): Action => {
  const action: Action = { type: "SaveSelectedArea", payload: area };
  return action;
};

export const SaveEnvironment = (environment: string): Action => {
  const action: Action = { type: "SaveEnvironment", payload: environment };
  return action;
};

export const ShowLoader = (): Action => {
  const action: Action = { type: "ShowLoader" };
  return action;
};

export const HideLoader = (): Action => {
  const action: Action = { type: "HideLoader" };
  return action;
};

export const ShowCountryCityPicker = (): Action => {
  const action: Action = { type: "ShowCountryCityPicker" };
  return action;
};

export const HideCountryCityPicker = (): Action => {
  const action: Action = { type: "HideCountryCityPicker" };
  return action;
};

export const ShowAreaPicker = (): Action => {
  const action: Action = { type: "ShowAreaPicker" };
  return action;
};

export const HideAreaPicker = (): Action => {
  const action: Action = { type: "HideAreaPicker" };
  return action;
};

export const ShowSnackBar = (snackBarProps: SnackbarProps): Action => {
  const action: Action = { type: "ShowSnackbar", payload: snackBarProps };
  return action;
};

export const HideSnackBar = (): Action => {
  const action: Action = { type: "HideSnackbar" };
  return action;
};

export const ShowLocationPicker = (): Action => {
  const action: Action = { type: "ShowLocationPicker" };
  return action;
};

export const HideLocationPicker = (): Action => {
  const action: Action = { type: "HideLocationPicker" };
  return action;
};

export const SaveSelectedLocation = (location: Location): Action => {
  const action: Action = { type: "SaveSelectedLocation", payload: location };
  return action;
};
