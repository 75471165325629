import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Container,
} from "@material-ui/core";
import {
  Room as RoomIcon,
  MyLocation as MyLocationIcon,
} from "@material-ui/icons";

import { RouteChildrenProps } from "react-router-dom";

import ScreenWrapper from "../../components/ScreenWrapper/ScreenWrapper";
import { dispatch, useGlobalState } from "../../common/GlobalState";
import NavBar from "../../components/NavBar/NavBar";
import * as Actions from "../../common/GlobalActions";
import Logo from "../../components/Logo/Logo";
import { HijriModel } from "../../common/Types";
import ServiceManager from "../../common/ServiceManager";
import { APIs } from "../../common/Const";
import Utils from "../../common/Utils";

interface Props extends RouteChildrenProps {}

const HomeScreen: React.FC<Props> = ({ history }) => {
  const [selectedCity] = useGlobalState("SelectedCity");
  const [selectedArea] = useGlobalState("SelectedArea");
  const [selectedLocation] = useGlobalState("SelectedLocation");
  const [hijriData, setHijriData] = useState<HijriModel | undefined>(undefined);

  useEffect(() => {
    if (!selectedCity) {
      dispatch(Actions.ShowCountryCityPicker());
    }
  }, []);

  useEffect(() => {
    if (selectedArea) {
      //loadHijri();
    } else {
      setHijriData(undefined);
    }
  }, [selectedArea]);

  const btnAreaClicked = () => {
    dispatch(Actions.ShowAreaPicker());
  };

  const btnSearchByAreaClicked = () => {
    if (!selectedArea) {
      btnAreaClicked();
    } else {
      history.push(`/Masjids/${selectedArea.AreaID}/0/0`);
    }
  };

  const btnNearBySearchClicked = () => {
    if (!selectedLocation) {
      dispatch(Actions.ShowLocationPicker());
    } else {
      const { latitude, longitude } = selectedLocation;
      history.push(`/Masjids/0/${longitude}/${latitude}`);
    }
  };

  const loadHijri = async () => {
    const date = new Date();
    const dd = date.getDate();
    const MM = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    const response = await ServiceManager.CallService(
      APIs.GetHijriDate,
      {
        AreaID: selectedArea!.AreaID,
        EnglishDate: `${dd}-${MM}-${yyyy}`,
      },
      "POST",
      false
    );
    if (response) {
      const { ResultData } = response;
      setHijriData(ResultData as HijriModel);
    }
  };

  return (
    <ScreenWrapper>
      <NavBar
        title={"Awqat-e-Salah"}
        rightButtons={[
          {
            Icon: <RoomIcon />,
            onPress: () => {
              dispatch(Actions.ShowCountryCityPicker());
            },
          },
          {
            Icon: <MyLocationIcon />,
            onPress: () => {
              dispatch(Actions.ShowLocationPicker());
            },
          },
        ]}
      />
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 8,
        }}
      >
        <Card
          style={{
            marginTop: 75,
            marginBottom: 8,
            width: "100%",
          }}
        >
          <CardContent style={{ marginTop: 40 }}>
            <Typography>Search masjid citywise :</Typography>
            <Button
              fullWidth
              style={{ marginTop: 10, marginBottom: 15 }}
              onClick={btnAreaClicked}
            >
              {selectedArea ? selectedArea!.AreaName : "Select City"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={btnSearchByAreaClicked}
            >
              SEARCH
            </Button>
          </CardContent>
        </Card>
        <Card
          style={{
            marginBottom: 8,
            width: "100%",
          }}
        >
          <CardContent>
            <Typography>Search masjid near your location :</Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: 10 }}
              onClick={btnNearBySearchClicked}
            >
              NEARBY MASJID
            </Button>
          </CardContent>
        </Card>
        {hijriData && (
          <Card
            style={{
              marginBottom: 8,
              width: "100%",
            }}
          >
            <CardContent style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <Typography variant={"h4"} align={"center"}>
                  {hijriData.HijriDate.split("-")[0]}
                </Typography>
                <Typography variant={"h6"} align={"center"}>
                  {Utils.getMonthNameForNumber(
                    Number(hijriData.HijriDate.split("-")[1]),
                    false
                  )}
                </Typography>
                <Typography variant={"h6"} align={"center"}>
                  {hijriData.HijriDate.split("-")[2]}
                </Typography>
              </div>
              <Divider orientation="vertical" flexItem />
              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <Typography variant={"h4"} align={"center"}>
                  {hijriData.EnglishDate.split("-")[0]}
                </Typography>
                <Typography variant={"h6"} align={"center"}>
                  {Utils.getMonthNameForNumber(
                    Number(hijriData.HijriDate.split("-")[1]),
                    true
                  )}
                </Typography>
                <Typography variant={"h6"} align={"center"}>
                  {hijriData.EnglishDate.split("-")[2]}
                </Typography>
              </div>
            </CardContent>
          </Card>
        )}
        <Logo style={{ position: "absolute", top: 75 }} rounded />
      </Container>
    </ScreenWrapper>
  );
};

export default HomeScreen;
